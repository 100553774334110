@import url('https://fonts.googleapis.com/css?family=Handlee|Lobster|Roboto&display=swap');

.form-control, button {
  border-radius: 0 !important;
}

.App {
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.App .listContainer a {
  display: block;
  width: 100%;
  padding: 1.33em 1em;
}

.listAttributes small svg {
  vertical-align: text-bottom;
}

form {
  display: flex;
  justify-content: space-between;
  align-items: baseline !important;
}

.listContainer a {
  display: block;
}

.App h2,
.listHeadline h4 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.App h2 a {
  margin-right: .66rem;
}

.App h4 .badge {
  text-transform: uppercase;
}

.navbar .badge {
  font-weight: normal;
}

.navbar ul button {
  padding-left: 0;
}

.dropdown-toggle::before {
  display: none !important;
}

.App button.actionLink {
  padding: 0;
}

.App .dropdown-menu button {
  width: 100%;
  text-align: left;
}

.App h5 {
  display: inline;
  margin: 0;
}

.listContainer a {
  color: black;
  cursor: pointer;
  text-decoration: none !important;
}

#addNewListForm input,
#addNewItemForm input {
  border: 0;
  border-top: 1px solid #2e8b57;
}

#addNewListForm button,
#addNewItemForm button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .66em;
}

.listContainer .dropdown {
  width: 100%;
  text-align: center;
}

.listItem {
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listHeadline {
  display: flex;
  align-items: center;
  padding: 2.33em 1.25em;
  margin-bottom: 3px;
  justify-content: space-between;
}

.listHeadline h4 {
  margin-bottom: 0;
}

.listMenu .btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.menu-separator {
  border-left: 3px solid #fff;
}

footer {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: white;
}

.App .listContainer {
  margin-bottom: 106px;
}

.navbar {
  background-color: #2e8b57;
}

.update-notification {
  background-color: #222;
  color: #fff;
  display:block;
  position: absolute;
  bottom:0;
  right:0;
  padding:0.8em 1.2em;
  text-decoration: none;
  z-index: 999;
}

.swal2-input {
  text-align: center;
}

.tickItemButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2em 1.25em;
  position: relative;
  overflow: hidden;
}

.tickItemButton .itemName {
  font-size: 18px;
}

.tickItemButton .itemCount,
.totalTicks {
  font-size: 24px;
  font-family: 'Handlee', cursive;
  line-height: 1;
  font-weight: bold;
}

.App .navbar-brand .brand-name {
  font-family: 'Lobster', cursive;
  font-size: 28px;
  line-height: 1;
}

.version-badge {
  margin-right: 1em;
  font-size: 11px;
}

.tutorial-card {
}

.tutorial-card p {
  display: block;
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  background: #f0f0f0;
  border-radius: 0 5px 5px 5px;
  float: left;
}

.tutorial-card p::after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #f0f0f0 transparent transparent;
  top: 0;
  left: -10px;
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.tutorial-card p .metadata {
  
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.tutorial-card p .metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 11px;
  display: inline-block;
}

.navbar small:first-of-type {
  margin-top: 1em;
}

.navbar small {
  color: white;
  display: block;
  padding: .25em 0;
}

.navbar small a {
  color: white !important;
  text-decoration: underline;
}

.ticks {
  margin-bottom: 107px;
}

.errorContainer {
  text-align: center;
}

.errorContainer h1,
.errorContainer h2 {
  width: 100%;
  padding: .66rem;
  display: block;
}

.totals {
  border-top: 4px double #666;
  padding: .66em 0;
}

.tickItem .tick-arrow {
  font-size: 280px;
  position: absolute;
  opacity: .23;
  display: block;
}
.tickItem .tick-arrow.tick-arrow-up {
  right: -60px;
  top: -40px;
  z-index: 10;
}
.tickItem .tick-arrow.tick-arrow-down {
  font-size: 190px;
  left: -40px;
  top: -75px;
  z-index: 5;

}

.tickItemButton span {
  pointer-events: none;
}
